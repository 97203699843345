<template>
    <div class="modal fade show add-note-modal" aria-labelledby="applyTdsVds">
        <div class="modal-dialog modal-dialog-centered modal-custom" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center px-1 my-1 align-item-center">
                            <h1 class="mb-0">Apply TDS VDS</h1>
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0">

                <div class="modal-body pb-5">
                    <div class="row gy-2 px-2">
                        <div class="col-12 col-md-6 col-lg-6 mr-top-5">
                            <label for="colFormLabel" class="col-form-label">Account Head</label>
                            <div class="product-select">
                                <v-select
                                    placeholder="Select Account Head"
                                    v-model="billData.account_head_id"
                                    :options="accountHeads"
                                    label="name"
                                    :reduce="name => name.id"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 mr-top-5 account-head"></div>
                        <div class="col-12 col-md-3 col-lg-3">
                            <label class="form-label">Rate</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    name="rate" 
                                    placeholder="Enter rate"
                                    class="form-control"
                                    v-model="billData.rate"
                                    @input="onChangeBaseInfo"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-2 col-lg-2">
                            <label class="form-label">Quantity</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    name="rate" 
                                    placeholder="Enter quantity"
                                    class="form-control"
                                    v-model="billData.quantity"
                                    @input="onChangeBaseInfo"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-2 col-lg-2">
                            <label class="form-label">Amount</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    name="amount"
                                    disabled
                                    placeholder="Amount"
                                    class="form-control"
                                    v-model="amount"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-2 col-lg-2">
                            <label class="form-label">Discount Amount</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    name="discount_amount" 
                                    placeholder="Enter discount amount"
                                    class="form-control"
                                    v-model="billData.discount_amount"
                                    @input="onChangeBaseInfo"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3">
                            <label class="form-label">After Discount Amount</label>
                            <div class="input-group">
                                <input
                                    type="number"
                                    min="1"
                                    disabled
                                    name="after_discount_amount" 
                                    placeholder="Enter discount amount"
                                    class="form-control"
                                    v-model="afterDiscountAmount"
                                />
                            </div>
                        </div>
                        <hr>
                        <div class="col-12 mr-top-5">
                            <div class="summary-card">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>VAT Amount</td>
                                            <td class="currency">{{ commaFormat(vatAmount) }}</td>
                                        </tr>
                                        <tr class="border-top">
                                            <td>Total Bill Amount</td>
                                            <td class="currency">{{ commaFormat(totalBillAmount) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="d-flex justify-content-start align-items-center">
                                                <span class="me-2">VDS / Mushak 6.3 Amount</span>
                                                <div class="select-head">
                                                    <v-select
                                                        v-model="vdsAccountHeadId"
                                                        placeholder="Select VDS Payable"
                                                        class="w-80"
                                                        :options="vdsPayableHeads"
                                                        label="name"
                                                        :reduce="(name) => name.id"
                                                    />
                                                </div>
                                            </td>
                                            <td class="currency">{{ commaFormat(vatAmount) }}</td>
                                        </tr>
                                        <tr>
                                            <td class="d-flex justify-content-start align-items-center">
                                                <span class="me-5">TDS Amount</span>
                                                <div class="select-head">
                                                    <v-select
                                                        v-model="tdsAccountHeadId"
                                                        placeholder="Select TDS Payable"
                                                        class="w-80"
                                                        :options="tdsPayableHeads"
                                                        label="name"
                                                        :reduce="(name) => name.id"
                                                    />
                                                </div>
                                            </td>
                                            <td class="currency">{{ commaFormat(tdsAmount) }}</td>
                                        </tr>
                                        <tr class="highlight-row">
                                            <td>Net Payable Amount</td>
                                            <td class="currency d-flex justify-content-end">
                                                <input
                                                    min="1"
                                                    class="form-control text-right w-50"
                                                    v-model="netSupplierPayment"
                                                    name="vat_amount"
                                                    type="number"
                                                    placeholder="Net Payable Amount"
                                                    @input="onChangeSupplierAmount"
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12 mr-top-5">
                            <div class="table-responsive">
                                <table class="table custom-table-padding">
                                    <thead>
                                        <tr>
                                            <th width="20%">HS / Service Code</th>
                                            <th width="30%">VDS Head</th>
                                            <th width="12%">Base Amount</th>
                                            <th width="12%">VDS rate</th>
                                            <th width="12%">VDS amount / Mushak 6.3</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <v-select
                                                    placeholder="Select Service Code"
                                                    label="code"
                                                    v-model="vdsFormData.id"
                                                    :options="vdsRateList"
                                                    :reduce="item => item.id"
                                                />
                                            </td>
                                            <td>
                                                <v-select
                                                    placeholder="Select VDS Head"
                                                    label="name"
                                                    v-model="vdsFormData.id"
                                                    :options="vdsRateList"
                                                    :reduce="item => item.id"
                                                />
                                            </td>
                                            <td class="text-right">
                                                <span>{{ commaFormat(baseAmount) }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span>{{ vdsFormData.rate }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span>{{ commaFormat(vatAmount) }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12 mr-top-5">
                            <div class="table-responsive">
                                <table class="table custom-table-padding">
                                    <thead>
                                        <tr>
                                            <th width="20%">TDS Section Ref</th>
                                            <th width="30%">TDS Head</th>
                                            <th width="12%">Base Amount</th>
                                            <th width="12%">TDS rate</th>
                                            <th width="12%">TDS amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <v-select
                                                    placeholder="Select TDS Section Ref"
                                                    label="code"
                                                    v-model="tdsFormData.id"
                                                    :options="tdsRateList"
                                                    :reduce="item => item.id"
                                                />
                                            </td>
                                            <td>
                                                <v-select
                                                    placeholder="Select TDS Head"
                                                    label="name"
                                                    v-model="tdsFormData.id"
                                                    :options="tdsRateList"
                                                    :reduce="item => item.id"
                                                />
                                            </td>
                                            <td class="text-right">
                                                <span>{{ commaFormat(baseAmount) }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span>{{ tdsFormData.rate }}</span>
                                            </td>
                                            <td class="text-right">
                                                <span>{{ commaFormat(tdsAmount) }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="col-12 mr-top-5">
                            <div class="mb-1">
                                <label class="form-label" for="description">TDS VDS Note</label>
                                <vField
                                    as="textarea"
                                    v-model="tdsVdsNote"
                                    class="form-control"
                                    name="description"
                                    rows="2"
                                />
                            </div>
                        </div>
                        
                        <div class="col-12 text-center">
                            <SubmitCancelBtn
                                @onSubmit="submit"
                                @onCancel="closeModal"
                                :loading="loading"
                                submitTxt="Save"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import figureFormatter from "@/services/utils/figureFormatter";
import { computed } from "vue";
import SubmitCancelBtn from '@/components/atom/SubmitCancelBtn.vue';
import cloneDeep from "lodash/cloneDeep";
import round from "lodash/round";

const store = useStore();
const route = useRoute();
const loading = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const { commaFormat } = figureFormatter();

const props = defineProps({
    data: {
        type: Object || null,
        default: null
    },
    tdsRateList: {
        type: Array,
        default: []
    },
    vdsRateList: {
        type: Array,
        default: []
    },
    onSaveTdsVds: {
        type: Function,
        default: () => {}
    },
    tdsPayableHeads: {
        type: Array,
        default: []
    },
    vdsPayableHeads: {
        type: Array,
        default: []
    },
    accountHeads: {
        type: Array,
        default: []
    }
})

const emit = defineEmits(['onSaveTdsVds']);

const getVatFormData = () => {
    return {
        id: '',
        rate: 0
    }
}

const billData = ref(cloneDeep(props.data));
const vdsFormData = ref(getVatFormData());
const tdsFormData = ref(getVatFormData());
const initialDiscountAmount = ref(0);
const netSupplierPayment = ref(0);
const tdsAccountHeadId = ref(null);
const vdsAccountHeadId = ref(null);
const tdsVdsNote = ref(null);

const amount = computed(() => {
    return billData.value.rate * billData.value.quantity
})

const setAfterDiscountAmount = () => {
    const discountAmount = billData.value.discount_amount === '' ? 0 : parseFloat(billData.value.discount_amount)
    if(!discountAmount) {
        initialDiscountAmount.value = amount.value;
        return;
    }
    initialDiscountAmount.value = round(amount.value - discountAmount, 2);
}

const afterDiscountAmount = computed(() => {
    const discountAmount = billData.value.discount_amount === '' ? 0 : parseFloat(billData.value.discount_amount)
    if(!discountAmount) {
        return round(amount.value, 2);
    }
    return round(amount.value - discountAmount, 2);
})

const baseAmount = computed(() => {
    return afterDiscountAmount.value;
});

const vatAmount = computed(() => {
    return round((baseAmount.value * parseFloat(vdsFormData.value.rate) / 100), 2);
});

const tdsAmount = computed(() => {
    return round((baseAmount.value * parseFloat(tdsFormData.value.rate) / 100), 2);
});

const totalBillAmount = computed(() => {
    return round((baseAmount.value + vatAmount.value), 2);
})

const netPayableAmount = computed(() => {
    return round((totalBillAmount.value - vatAmount.value - tdsAmount.value), 2);
})

const onChangeBaseInfo = () => {
    netSupplierPayment.value = netPayableAmount.value;
}

const onChangeSupplierAmount = () => {
    const baseAmount = netSupplierPayment.value / (100 - parseFloat(tdsFormData.value.rate)) * 100;
    const amount = baseAmount + parseFloat(billData.value.discount_amount || 0);
    billData.value.rate = round((amount / billData.value.quantity), 2);
}

const closeModal = () => {
    store.state.isModalOpen = false;
}

const submit = () => {

    if(checkValidation()) {
        return;
    }

    let data = {
        account_head_id: billData.value.account_head_id,
        tds_rate_id: tdsFormData.value.id,
        vds_rate_id: vdsFormData.value.id,
        tds_amount: tdsAmount.value,
        vds_amount: vatAmount.value,
        vat_amount: vatAmount.value,
        rate: billData.value.rate,
        quantity: billData.value.quantity,
        discount_amount: billData.value.discount_amount,
        base_amount: baseAmount.value,
        net_payable_amount: netPayableAmount.value,
        debit: netPayableAmount.value,
        total_amount: totalBillAmount.value,
        is_vat_added: true,
        is_set_product: false,
        tds_account_head_id: tdsAccountHeadId.value,
        vds_account_head_id: vdsAccountHeadId.value,
        description: tdsVdsNote.value
    }

    emit('onSaveTdsVds', data);
    closeModal();
}

const checkValidation = () => {
    let isInvalid = false;

    if(! billData.value.account_head_id) {
        isInvalid = true;
        showError('Please Select an Account Head.');
    }

    if(! tdsAccountHeadId.value) {
        isInvalid = true;
        showError('Please Select TDS Account Head.');
    }

    if(! vdsAccountHeadId.value) {
        isInvalid = true;
        showError('Please Select VDS Account Head.');
    }

    return isInvalid
}

const setInitialData = () => {
    if(! billData.value.quantity) billData.value.quantity = 1;
    if(billData.value.vds_rate_id) vdsFormData.value.id = billData.value.vds_rate_id;
    if(billData.value.tds_rate_id) tdsFormData.value.id = billData.value.tds_rate_id;
    if(billData.value.description) tdsVdsNote.value = billData.value.description;
    if(billData.value.discount_receipt_amount) billData.value.discount_amount = billData.value.discount_receipt_amount;
}

const setInitialTdsVds = () => {
    const tdsHeadId = billData.value.tds_account_head_id || (props.tdsPayableHeads.length ? props.tdsPayableHeads[0].id : null);
    const vdsHeadId = billData.value.vds_account_head_id || (props.vdsPayableHeads.length ? props.vdsPayableHeads[0].id : null);

    tdsAccountHeadId.value = tdsHeadId;
    vdsAccountHeadId.value = vdsHeadId;
}

watch(() => vdsFormData.value.id, (newValue) => {
    if(! newValue) {
        vdsFormData.value.rate = 0;
        return;
    }

    const vat = props.vdsRateList.find(item => item.id == newValue);
    if(! vat) return;

    vdsFormData.value.rate = vat.rate;
    onChangeBaseInfo();
})

watch(() => tdsFormData.value.id, (newValue) => {
    if(! newValue) {
        tdsFormData.value.rate = 0;
        return;
    }

    const vat = props.tdsRateList.find(item => item.id == newValue);
    if(! vat) return;

    tdsFormData.value.rate = vat.rate;
    onChangeBaseInfo();
})

watch(() => billData.value.account_head_id, (newValue, oldValue) => {
    
    if(! newValue) {
        vdsFormData.value.id = null;
        tdsFormData.value.id = null;
        return;
    }

    const accountHead = props.accountHeads.find(item => item.id == newValue);
    if(! accountHead) return;
   
    vdsFormData.value.id = accountHead.vds_rate_id;
    tdsFormData.value.id = accountHead.tds_rate_id;

}, { immediate: true });

onMounted(() => {
    setAfterDiscountAmount();
    setInitialData();
    setInitialTdsVds();

    netSupplierPayment.value = billData.value.debit;
    onChangeSupplierAmount();
})
</script>

<style scoped>
.select-head {
    width: 70%;
}
.table-responsive {
    overflow-x: visible !important;
}
.modal-custom{
    max-width: 75vw;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
.mr-top-5 {
    margin-top: 8px;
}
.highlight-row {
    font-weight: bold;
}
.net-negative {
    color: #dc3545;
}
td {
    padding: 5px 0;
}
td:first-child {
    padding-right: 8px;
}
.currency {
    text-align: right;
}
table {
    width: 100%;
    border-collapse: collapse;
}
.border-bottom {
    border-bottom: 1px solid #dee2e6;
}
.product-select {
    width: 70%;
}
.account-head {
    display: flex;
    justify-content: end;
    align-items: center;
}
</style>
