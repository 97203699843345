
export const usePayment = () => {

    const formatPaymentTransactions = (ledgerMaster, payment, companyId, newLedger = null) => {

        if(! ledgerMaster) return null;

        const formattedData = {
            company_id: companyId,
            contact_profile_id: ledgerMaster.contact_profile_id,
            ledger_master_id: newLedger.id,
            payment_date: payment.date,
            voucher_no: newLedger.voucher_no,
            payment_reference: payment.payment_reference,
            payment_reference_date: payment.payment_reference_date,
            account_head_id: ledgerMaster.account_head_id,
            total_paid_amount: ledgerMaster.credit,
            description: payment.note,
            payment_generals: []
        }

        const paymentGeneral = ledgerMaster.payment_generals.map(item => {
            return {
                id: item.payment_general ? item.payment_general.id : null,
                account_head_id: item.account_head_id,
                vds_payable_amount: item.vds_amount ? item.vds_amount : 0,
                tds_payable_amount: item.tds_amount ? item.tds_amount : 0,
                discount_amount: item.discount_amount,
                rate: item.rate || 0,
                quantity: item.quantity || 0,
                base_amount: item.base_amount || 0,
                bill_amount: item.total_amount || 0,
                total_amount: item.net_payable_amount || item.debit,
                tds_rate_id: item.tds_rate_id,
                vds_rate_id: item.vds_rate_id,
                tds_account_head_id: item.tds_account_head_id,
                vds_account_head_id: item.vds_account_head_id,
                description: item.description
            }
        })

        formattedData.payment_generals = paymentGeneral;

        return formattedData
    }

    return {
        formatPaymentTransactions
    }
}